/* eslint-disable react/prop-types */
import React from 'react';

import { Radar, defaults } from 'react-chartjs-2';

import './MissionStatement.scss';

defaults.plugins.legend.display = false;



export default function missionStatement({ chartData, chartOptions, profile, businessValue }) {
  

  if (profile?.company.name === "YRCI" || profile?.company.data.name === "YRCI") {
    chartData.labels = ['Excellence', 'Teamwork', 'Flexibility', 'Integrity']
  }

  return (


    <div className="missionStatement">
      <div className="title">
        <span><b>Business Values</b></span>
      </div>

      <div className="missionSection">
        <div className="radarDiv">


          <Radar data={chartData} options={chartOptions} />

        </div>
        {(profile.company.data.name === "YRCI") || (profile.company.name === 'YRCI') ?
          <div className="missionList">
            <p><b>Excellence</b> We embrace challenges and do our best. Never satisfied by meeting minimum requirements, we are constantly driven to go above and beyond. YRCI delivers!</p>
            <p><b>Teamwork</b> We achieve more by embracing the diverse talents and abilities of our colleagues and engaging our customers through collaboration.</p>
            <p><b>Flexibility</b> We respond to circumstances and expectations of our employees, as well as our environment and clients, balancing the needs of each.</p>
            <p><b>Integrity</b> We do what's right, are honest and trustworthy, and always speak the truth.</p>
          </div> :

          <div className="missionList">
            <p><b>Shape the Future</b> demands that a person possess a high degree of security to accept criticism while maintaining their vision (i.e. Steadiness), as well as self-discipline and fortitude to be able to see their creative vision come to fruition (i.e. Persistence).</p>
            <p><b>Deep Research</b> demands that a person be intellectually curious (i.e. creativity), as well as be open to learn from others and be mentored (i.e. Cooperativeness).</p>
            <p><b>Earn Customer Loyalty</b> requires that vendors know how to interact effectively with others (i.e. Sociability) as well as be perceived as trustworthy (i.e. Cooperativeness).</p>
            <p><b>Creativity & Originality</b> demands that a person possess a high degree of imagination (i.e. Creativity), as well as the self-discipline (i.e. Persistence) to bring their creations to life.</p>
          </div>}

          {/* <div className="missionList">
            <p><b>{businessValue?.title1}</b> {businessValue?.content1}</p>
            <p><b>{businessValue?.title2}</b> {businessValue?.content2}</p>
            <p><b>{businessValue?.title3}</b> {businessValue?.content3}</p>
            <p><b>{businessValue?.title4}</b> {businessValue?.content4}</p>
          </div> */}

      

      </div>
    </div>

  );
}