import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import '../CCC.scss';
import * as ROUTES from '../../../constants/routes';
import { Redirect } from 'react-router-dom';
import AppConfig from '../../App/AppConfig';
const { v4: uuidv4 } = require('uuid');

const SearchType = {
  Giphy: 'GIPHY',
  Youtube: 'YOUTUBE',
  Google: 'GOOGLE',
  Imgur: 'IMGUR LINK',
  Upload: 'UPLOAD',
};


const INITIAL_STATE = {
  selected: false,
  selectedContent: '',
  selectedModal: false,
  attributeError: false,
  textArea: '',
  timeStamp: null,
  attributesLimit: 300,
  redirect: false,
  prevContent: '',
  answered: false,
  contentType: 0,
  // searchChanged: false,
};

class RenderCCC extends Component {
  constructor(props) {
    super(props);

    const showEdit = props.showEditModal !== undefined ? props.showEditModal : false;
    this.state = { editModal: showEdit, ...INITIAL_STATE };
  };
  static defaultProps = {
    giphyResults: [],
    googleResults: [],
    youtubeResults: [],
    imgurResults: {},
    timeStamp: null,
    searchType: SearchType.Giphy,
    id: '',
    title: '',
    sub: '',
    changed: false,
  }

  async componentWillMount(){
    let results = null;

    // results = this.props.profile.data.profiles.default.vibeAnswers;
    let att = {
      answer : '',
      content : '',
      contentType : 0,
    };
    if (results && this.props.id in results){
      att = results[this.props.id];
      this.setState({
        textArea: att.answer,
        prevContent:att.content,
        answered: true,
        contentType: att.contentType,
      });
    }
  }

  // Persists selected content to db
  async selectContent(item) {
    if (this.props.onItemSelect) {
      this.props.onItemSelect(item);
      return;
    }
    this.setState({
      selected: true,
      selectedContent: item,
      selectedModal: true,
    });
  };

  // Renders an image tag containing a Giphy animated .gif item
  renderGiphy = (item) => (
    <div className='contentDiv' onClick={() => this.selectContent(item)}>
      <img
        src={item.images.downsized_medium.url}
        className='content'
        alt={item.images.downsized_medium.url} />
      <div 
        className='overlay' />
    </div>
  );

  // Renders an image tag containing a thumbnail of a YouTube video item
  renderYoutube = (item) => (
    <div className='contentDiv'>    
      <img
        src={item.snippet.thumbnails.medium.url}
        className='content'
        alt={item.snippet.thumbnails.medium.url} />
      <div 
        className='overlay' 
        onClick={() => this.selectContent(item)} />
    </div>
  );

  // Renders an image tag containing a playable YouTube video item
  renderYoutubeModal = (item) => (
    <div className='contentDiv'>    
      <div className='iframe-container'>
        <iframe 
          className='embed-responsive-item' 
          src={'https://www.youtube.com/embed/' + item.id.videoId} 
          title='Selected Content' 
          scrolling="no"/>
      </div>
    </div>
  );

  // Renders an image tag containing a thumbnail of a Google image item
  renderGoogle = (item) => (
    <div className='contentDiv'>
      <img
        src={item.link}
        className='content'
        alt={item.title} />
      <div 
        className='overlay' 
        onClick={() => this.selectContent(item)} />
    </div>
  );
  renderImgur = (item) => (
    <div className='contentDiv'>
      <img
        src={item.link}
        className='content'
        alt={item.title} />
      <div 
        className='overlay' 
        onClick={() => this.selectContent(item)} />
    </div>
  );

  handleClose = ()=> {
    this.setState({
      selectedModal: false,
      editModal: false,
    });
    this.props.setShowEditModal(false);
  }

  // Renders an image tag containing a thumbnail of a Google, Giphy, or Youtube image item
  renderContent() {
    const { 
      searchType, 
      giphyResults, 
      youtubeResults, 
      googleResults,
      imgurResults,
    } = this.props;

    let searchResults = <></>;

    switch (searchType) {
      case SearchType.Giphy:
        searchResults = 
          giphyResults.map((item, index) => 
            <div key={index + ' ' + searchType}>
              {this.renderGiphy(item)}
            </div>
          );
        break;

      case SearchType.Youtube:
        searchResults = youtubeResults.map((item, index) =>
            <div key={index + ' ' + searchType}>
              {this.renderYoutube(item)}
            </div>
          );
        break;

      case SearchType.Google:
          searchResults = googleResults.map((item, index) => 
              <div key={index + ' ' + searchType}>
                {this.renderGoogle(item)}
              </div>
            );
        break;
        case SearchType.Upload:
          case SearchType.Imgur:
            if (imgurResults && imgurResults.link) {
              searchResults = 
                <div className='imgurLinkDump'>
                  {this.renderImgur(imgurResults)}
                </div>;
            } else {
              searchResults = <div></div>;
            }
          break;

      default: 
        searchResults = <div></div>;
        break;
    }

    return (
      <div className='render-container'>
        { this.state.error ? 
          (<div className='errorMsg'>{this.state.errorMsg}</div>) : 
          (<>{searchResults}</>)
        }
      </div>
    );

  }

  // Handles the textArea changes after selecting a content
  handleTextAreaChange = (e)=> {
    let text = 
      e.target.value.substring(0, 
        Math.min(e.target.value.length, this.state.attributesLimit));
    this.setState({
      textArea: text,
    });
  };

  /// returns the number of characters left that a user can 
  /// input for each atttribute
  returnattributesLimit(){
    let Limit = this.state.attributesLimit
    try{
      Limit = this.state.attributesLimit - this.state.textArea.length;
    } catch(e){}
    
    return Limit    
  };

  attributeError = ()=> {
    this.setState({
      attributeError: true,
    })
  }

  saveAnswers = async ()=> {
    const {selectedContent, textArea} = this.state;
    const { searchType, id, offset, limit, term } = this.props;
    if (textArea !== '' && textArea !== null && textArea !== undefined){
      let contentURL = '';
      let contentType = 0;
      switch (searchType) {
        case SearchType.Giphy:
            contentURL = selectedContent.images.downsized_medium.url;
            contentType = 1;
            break;
        case SearchType.Youtube:
            contentURL = 'https://www.youtube.com/embed/' + selectedContent.id.videoId;
            contentType = 2;
            break;
        case SearchType.Google:
            contentURL = selectedContent.link;
            contentType = 3;
            break;
        case SearchType.Upload:
        case SearchType.Imgur:
             contentURL = selectedContent.link;
             contentType = 4;
             break;
        default: break;
      }

      if (!this.props.profile.data.profiles.default.vibeAnswers){
        this.props.profile.data.profiles.default.vibeAnswers = {};
      }

      if (!(id in this.props.profile.data.profiles.default.vibeAnswers)){
        this.props.profile.data.profiles.default.vibeAnswers[id] = {};
      }
      let userAnswer = this.props.profile.data.profiles.default.vibeAnswers[id];
      if(userAnswer.answer && userAnswer.answer.length > 0){
        let previousAnswers = userAnswer.previousAnswers;
        if (previousAnswers === undefined){
          previousAnswers = []
        }
        previousAnswers.push({
          answer: userAnswer.answer ? userAnswer.answer:"err: missing",
          content: userAnswer.content ? userAnswer.content: "err: missing",
          contentType: userAnswer.contentType ? userAnswer.contentType: "err: missing",
          selectedTerm: userAnswer.selectedTerm ? userAnswer.selectedTerm: "err: missing",
          selectedTermPage: userAnswer.selectedTermPage ? userAnswer.selectedTermPage: "err: missing",
          searchedTerms: userAnswer.searchedTerms ? userAnswer.searchedTerms: "err: missing",
          timestamp: userAnswer.timeStamp ? userAnswer.timeStamp: Date.now(),
          vibeId: userAnswer.vibeId ? userAnswer.vibeId: undefined,
          likes: userAnswer.likes ? userAnswer.likes: 0,
		      likedBy: userAnswer.likedBy ? userAnswer.likedBy: [],
        })
        previousAnswers = this.addVibeIdsForOldData(previousAnswers);
        userAnswer.previousAnswers = previousAnswers;
      }
      if(!this.props.profile.data.userRoles){
				this.props.profile.data.userRoles = ['USER'];
			}else if ( this.props.profile.data.userRoles && this.props.profile.data.userRoles.length > 0 && this.props.profile.data.userRoles.indexOf('USER') === -1) {
				this.props.profile.data.userRoles.push('USER');
			}
      userAnswer.answer = textArea;
      userAnswer.content = contentURL;
      userAnswer.contentType = contentType;
      userAnswer.selectedTerm = term;
      userAnswer.selectedTermPage = (offset/limit) + 1;
      userAnswer.timeStamp = Date.now();
      userAnswer.likes = 0;
      userAnswer.likedBy = []; 
      userAnswer.vibeId = uuidv4();
      
      console.log("this.props.profile :: ", this.props.profile);
      console.log("this.props.profile.company.name :: ", this.props.profile.data.company.name);
      // const data = {"user_id":this.props.profile.uid,"vibe_url":contentURL,"company_name":this.props.profile.data.company.name,"vibe_title":textArea};
      // await this.props.profile.saveUserVibe(data);
      this.props.profile.save();
      this.setState({
        redirect: true,
        selected: false,
        
        selectedModal: false,
      });
    }
  }


  addVibeIdsForOldData(previousAnswers){
    for (var i in previousAnswers) {
      console.log('i ', i, 'previousAnswers[i].vibeId', previousAnswers[i].vibeId);
      if(!previousAnswers[i].vibeId){
        previousAnswers[i].vibeId = uuidv4();
      }
      if(!previousAnswers[i].likes && previousAnswers[i].likes !== 0){
        previousAnswers[i].likes = 0;
      }
      if(!previousAnswers[i].likedBy){
        previousAnswers[i].likedBy = [];
      }
    }
    return previousAnswers;
}

  saveEditedAnswers = async ()=> {
    const { textArea, prevContent, contentType } = this.state;
    const { id, } = this.props;
    if (textArea !== '' && textArea !== null && textArea !== undefined){
      let contentURL = '';
      switch (contentType) {
        case 1:
            contentURL = prevContent;
            break;
        case 2:
            contentURL = prevContent;
            break;
        case 3:
            contentURL = prevContent;
            break;
        case 4:
            contentURL = prevContent;
            break;
        default: break;
      }
      if (!this.props.profile.data.profiles.default.vibeAnswers){
        this.props.profile.data.profiles.default.vibeAnswers = {};
      }

      if (!(id in this.props.profile.data.profiles.default.vibeAnswers)){
        this.props.profile.data.profiles.default.vibeAnswers[id] = {};
      }
      let userAnswer = this.props.profile.data.profiles.default.vibeAnswers[id];
      if(userAnswer.answer && userAnswer.answer.length > 0){
        let previousAnswers = userAnswer.previousAnswers;
        if (previousAnswers === undefined){
          previousAnswers = []
        }
        previousAnswers.push({
          answer: userAnswer.answer ? userAnswer.answer:"err: missing",
          content: userAnswer.content ? userAnswer.content: "err: missing",
          contentType: userAnswer.contentType ? userAnswer.contentType: "err: missing",
          selectedTerm: userAnswer.selectedTerm ? userAnswer.selectedTerm: "err: missing",
          selectedTermPage: userAnswer.selectedTermPage ? userAnswer.selectedTermPage: "err: missing",
          searchedTerms: userAnswer.searchedTerms ? userAnswer.searchedTerms: "err: missing",
          timestamp: Date.now(),
        })
        userAnswer.previousAnswers = previousAnswers;
      }
      userAnswer.answer = textArea;
      userAnswer.content = contentURL;
      userAnswer.contentType = contentType;
      this.props.profile.save();
      this.setState({
        redirect: true,
      })
    }
  }

  selectionModal = ()=> {
    const { selected, selectedModal, selectedContent, attributeError, textArea } = this.state;
    const { title, sub, searchType } = this.props;
    let displayContent = (<div></div>)
    if(selected){
      switch (searchType) {
        case SearchType.Giphy:
            displayContent = (this.renderGiphy(selectedContent))
            break;
        case SearchType.Youtube:
            displayContent = (this.renderYoutubeModal(selectedContent))
            break;
        case SearchType.Google:
            displayContent = (this.renderGoogle(selectedContent))
            break;
        case SearchType.Upload:
        case SearchType.Imgur:
            displayContent = (this.renderImgur(selectedContent))
            break;
        default: break;
      }
    }
    return(
      <Modal 
        show={selectedModal} 
        onHide={() => this.setState({ selectedModal: false })} 
        animation={true} 
        size='md'
        keyboard>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className='attributeHeading'>{title}</p>
          <p className='attributeSub'>{sub}</p>
          {displayContent}
          <form id='attributeText'>
            <textarea
              id='attributeTextArea'
              name='textarea'
              className={attributeError ? ('attributeError'):('')}
              spellCheck={true}
              placeholder={AppConfig.isJobsMode() ?
                'Describe your vibe.'
                :
                'Describe your vibe.'
              } 
              value={textArea} 
              onChange={this.handleTextAreaChange} />
            <p className='textAreaCounter'>{this.returnattributesLimit() + ' chars left'}</p>
            {/* <label>What is your vibe color?</label><input type="color"></input> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className='submitDiv'>
            <button 
              type='button'
              value='CANCEL'
              className='btn-solid-purple mx-3 my-2'
              onClick={() => this.setState({ selectedModal: false })}>CANCEL
            </button>
            <button 
              type='submit'
              value='SAVE' 
              className={(textArea === '')? 'btn-disabled mx-3 my-2':'btn-solid-purple mx-3 my-2'} 
              disabled={textArea === ''}
              onClick={this.saveAnswers}>SAVE
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  editableModal = ()=> {
    const { attributeError, textArea, editModal, answered, prevContent, contentType } = this.state;
    const {  title, sub, } = this.props;
    let displayContent = (<div></div>)
    if(answered){
      switch (contentType) {
        case 1:
            displayContent = (
            <div className='contentDiv'>
              <img
                src={prevContent}
                className='content'
                alt={prevContent} />
            </div>
            )
            break;
        case 2:
          displayContent = (
            <div className='contentDiv'>    
              <div className='iframe-container'>
                <iframe 
                  className='embed-responsive-item' 
                  src={prevContent} 
                  title='Edit Content' 
                  scrolling="no"/>
              </div>
            </div>
            )
            break;
        case 3:
          displayContent = (
            <div className='contentDiv'>
              <img
                src={prevContent}
                className='content'
                alt={prevContent} />
            </div>
            )
            break;
        case 4:
          displayContent = (
            <div className='contentDiv'>
              <img
                src={prevContent}
                className='content'
                alt={prevContent} />
            </div>
                )
            break;
        default: break;
      }
    }
    return(
      <Modal 
        show={editModal} 
        onHide={this.handleClose} 
        animation={true} 
        size='md'
        keyboard>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <p className='attributeHeading'>{title}</p>
        <p className='attributeSub'>{sub}</p>
        {displayContent}
        <button onClick={() => {this.setState({ editModal: false }); this.props.setShowEditModal(false);}} className='editButton'> EDIT </button>
        <form id='attributeText'>
          <textarea
            id='attributeTextArea'
            name='textarea'
            className={attributeError ? ('attributeError'):('')}
            spellCheck={true}
            placeholder={AppConfig.isJobsMode() ?
              'Describe your vibe.'
              :
              'Describe your vibe.'
            } 
            value={textArea} 
            onChange={this.handleTextAreaChange} />
          <p className='textAreaCounter'>{this.returnattributesLimit() + ' chars left'}</p>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className='submitDiv'>
          <button 
            type='button'
            value='CANCEL'
            className='btn-solid-purple mx-3 my-2'
            onClick={() => this.setState({ redirect: true })}>CANCEL
          </button>
          <button 
            type='submit'
            value='SAVE' 
            className={(textArea === '')? 'btn-disabled mx-3 my-2':'btn-solid-purple mx-3 my-2'} 
            disabled={textArea === ''}
            onClick={this.saveEditedAnswers}>SAVE
          </button>
        </div>
      </Modal.Footer>
    </Modal>
    );
  }

  render() {
    const { redirect } = this.state;
    if (redirect){
      document.documentElement.scrollTop = 0;
      return <Redirect to={ROUTES.INSIGHTS+"/" + this.props.profile.uid} />
    };

    return (
      <div className='render'>
        {this.renderContent()}
        {this.selectionModal()}
        {this.editableModal()}
      </div>
    )
  }
};

export default RenderCCC;