import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import React, { Component } from 'react';
import { AuthDataContext } from '../../components/Firebase/authdataprovider';
import Cards from '../../components/Cards';
import Layout from '../../components/Layout';
import './MyContent.scss';
import Controllers from '../../components/Controllers';
import * as ROUTES from '../../constants/routes';
import AppTemplates from '../../constants/apptemplates';
import styled, { keyframes } from 'styled-components';
import { fadeInDown, fadeInUp } from 'react-animations';
import ScrollTopOnPageLoad from '../../components/ScrollTopOnPageLoad';
import AppConfig from '../../components/App/AppConfig'
import { Container, Jumbotron } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

const FadeDown = styled.div`animation: 0.6s ${keyframes`${fadeInDown}`}`;
const FadeUp = styled.div`animation: 0.6s ${keyframes`${fadeInUp}`}`;

const INITIAL_STATE = {
  userId: '',
};

class MyContentPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  };
  static defaultProps = {
    match: '',
  }

  componentDidMount = () => {
  }

  logData = (category, data, type, extra) => {
    // const logData = f.functions().httpsCallable('logData');
    // logData({category: category, data: data, type: type, extra: extra});
  }

  render() {
    
if (!this.props.profile.data?.profiles?.default?.email || this.props.profile.data?.profiles?.default?.email === "") {
  return <Redirect to={ROUTES.HOME} />
  }
    return (
      <Layout>
        <ScrollTopOnPageLoad/>
        <Container>
          <div className='MyContent'>
            <AuthDataContext.StreamConsumer requireAccount={true}>
              {profile => (
                <>
                  <FadeDown>
                    <Jumbotron className='text-center'>
                      <span className='heading'>PROFILE QUESTIONS</span>
                      {AppConfig.getCodeSnippetOption('myContentIntro')}
                    </Jumbotron>
                    <Controllers 
                      backRoute={ROUTES.HOME} 
                      nextRoute={ROUTES.MY_INFO}
                      className='top-controller' />
                  </FadeDown>
                  <FadeUp>
                    <Cards 
                      flip={true} 
                      edit={true} 
                      template={AppTemplates.default.softSkills}
                      softSkillsAnswers={profile.data.profiles.default.softSkillsAnswers} 
                      profile={profile}/>
                  </FadeUp>
                </>
              )}
            </AuthDataContext.StreamConsumer>
            <Controllers backRoute={ROUTES.HOME} nextRoute={ROUTES.MY_INFO} />
          </div>
        </Container>
      </Layout>
    );
  }
}


const condition = (profile) => {
  if (AppConfig.isJobsMode()){
    return profile.uid !== null;
  }

  return profile.uid !== null && profile.emailVerified === true;
}

export default compose(
  withAuthorization(condition),
)(MyContentPage);
